import axios from "axios";

export interface Response<T extends any> {
  code: number;
  message: string;
  data: T;
}

const instance = axios.create({
  // baseURL: "http://localhost:8000",
  baseURL: "https://api-i18n.levenx.com",
});

instance.interceptors.response.use(value => {
  return value.data;
});

export default instance;
