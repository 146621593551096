import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/home";
import Project from "./pages/project";
import NameSpace from "./pages/project/namespace";
import LocaleManager from "./pages/project/namespace/locale";
import CreateSource from "./pages/project/namespace/locale/create-source";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import LocaleLayout from "@/layouts/locale-layout";
import "./App.css";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Outlet />
        </QueryParamProvider>
      ),
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "projects",
          element: <Project />,
        },
        {
          path: "/locale",
          element: <LocaleLayout />,
          children: [
            {
              path: ":projectId",
              element: <NameSpace />,
            },
            {
              path: ":projectId/:namespace",
              element: <LocaleManager />,
            },
            {
              path: ":projectId/:namespace/create_source",
              element: <CreateSource />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
