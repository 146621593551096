import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div>
      <Button onClick={() => navigate("/projects")}>项目列表</Button>
    </div>
  );
}
