import axios from ".";

export interface ProjectField {
  _id: string;
  appKey: string;
  name: string;
  description: string;
}

export interface LocaleField {
  id: string;
  localeKey: string;
  defaultText: string;
  translateText: string;
}

export const getProjects = (): Promise<ProjectField[]> => {
  return axios.get(`/project`);
};

export const getOnlyProject = (id: string): Promise<ProjectField> => {
  return axios.get(`/project/only/${id}`);
};

export const createProject = (body: any) => {
  return axios.post("/project", body);
};
