import axios, { Response } from ".";

export interface NameSpace {
  _id: string;
  name: string;
  namespaceId: string;
  projectId: string;
}

export interface LocaleField {
  id: string;
  localeKey: string;
  defaultText: string;
  translateText: string;
}

export const getNamespaces = (projectId: string): Promise<NameSpace[]> => {
  return axios.get(`/namespace/${projectId}`);
};

export const getLocaleNamespace = (params: {
  projectId: string;
  namespace: string;
}): Promise<any> => {
  return axios.get(`/namespace/${params.projectId}/${params.namespace}`);
};

export const createLocale = (body: any) => {
  return axios.post("/locales", body);
};

export const createBatchLocale = (body: {
  namespaceId: string;
  locales: Array<any>;
}): Promise<Response<any>> => {
  return axios.post("/locales/batch", body);
};
