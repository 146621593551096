import { createModel } from "@rematch/core";
import { getProjects, getOnlyProject, ProjectField } from "@/services/project";
import { RootModel } from ".";

export const project = createModel<RootModel>()({
  state: {
    projects: [],
    currentProject: {} as ProjectField,
  } as {
    projects: ProjectField[];
    currentProject: ProjectField;
  },
  reducers: {
    setProjects: (state, payload) => {
      state.projects = payload;
      return state;
    },
    setCurrentProject: (state, payload) => {
      state.currentProject = payload;
      return { ...state };
    },
  },
  effects: dispatch => ({
    async fetchProjects() {
      const projects = await getProjects();
      dispatch.project.setProjects(projects);
    },
    async fetchCurrentProject(projectId: string) {
      const project = await getOnlyProject(projectId);
      dispatch.project.setCurrentProject(project);
    },
  }),
});
