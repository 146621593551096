import { createModel } from "@rematch/core";
import {
  getNamespaces,
  getLocaleNamespace,
  NameSpace,
} from "@/services/namespace";
import { RootModel } from ".";

export const namespace = createModel<RootModel>()({
  state: {
    namespaces: [],
    currentNamespace: {} as NameSpace,
  } as {
    namespaces: NameSpace[];
    currentNamespace: NameSpace;
  },
  reducers: {
    setNamespaces: (state, payload) => {
      state.namespaces = payload;
      return { ...state };
    },
    setCurrentNamespace: (state, payload) => {
      state.currentNamespace = payload;
      return { ...state };
    },
  },
  effects: dispatch => ({
    async fetchNamespaces(projectId: string) {
      const namespaces = await getNamespaces(projectId);
      dispatch.namespace.setNamespaces(namespaces);
    },
    async fetchCurrentNamespace(params: {
      projectId: string;
      namespace: string;
    }) {
      const namespace = await getLocaleNamespace(params);
      dispatch.namespace.setCurrentNamespace(namespace);
    },
  }),
});
