import { Outlet, useParams } from "react-router-dom";
import { Dispatch } from "@/models";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ProjectBreadcrumb from "@/views/project-breadcrumb";

export default function LocaleLayout() {
  const { projectId, namespace } = useParams();

  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (projectId) {
      dispatch.project.fetchCurrentProject(projectId);
      dispatch.namespace.fetchNamespaces(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (namespace && projectId) {
      dispatch.namespace.fetchCurrentNamespace({ projectId, namespace });
    }
  }, [namespace, projectId]);

  return (
    <div>
      <div className="h-16 bg-cyan-500 flex-shrink-0"></div>
      <ProjectBreadcrumb />
      <Outlet />
    </div>
  );
}
