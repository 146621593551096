import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { getLocaleNamespace, createBatchLocale } from "../../../../services/namespace";
import { Button, Input, Table, message } from "antd";
import { useState } from "react";
import { uniq } from "lodash-es";

export default function CreateSource() {
  const { namespace = "" } = useParams();

  const navigate = useNavigate();

  const { data: namespaceData } = useQuery([`getLocaleNamespace`], () =>
    getLocaleNamespace({
      projectId: "66000f82038640551fed6fb6",
      namespace: namespace,
    }),
  );

  const [locales, setLocales] = useState<Array<any>>(() =>
    new Array(10).fill(0).map((_, index) => ({
      index,
      localeKey: "",
      sourceText: "",
    })),
  );

  console.log("locales:", locales);

  const columns = [
    {
      title: "Key",
      dataIndex: "index",
      key: "index",
      width: 300,
      render: (index: number) => {
        const locale = locales[index];
        return (
          <div className="flex items-center gap-2">
            <span>{index + 1}</span>
            <Input.TextArea
              autoSize
              value={locale.localeKey}
              onChange={event => {
                locales[index] = { ...locale, localeKey: event.target.value };
                setLocales([...locales]);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "源文案",
      dataIndex: "sourceText",
      key: "sourceText",
      render: (_, item: any) => {
        const index = item.index;
        const locale = locales[index];
        return (
          <div>
            <Input.TextArea
              autoSize
              value={locale.sourceText}
              onChange={event => {
                locales[index] = {
                  ...locale,
                  sourceText: event.target.value,
                };
                setLocales([...locales]);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "",
      width: 200,
      render: () => {
        return (
          <div>
            <span>删除</span>
          </div>
        );
      },
    },
  ];

  const onSubmit = async () => {
    console.log("locales:", locales);
    const localeKeys = locales.map(locale => locale.localeKey).filter(Boolean);
    const uniqLocaleKeys = uniq(localeKeys);
    if (localeKeys.length !== uniqLocaleKeys.length) {
      message.error("存在重复值");
      return;
    }

    const res = await createBatchLocale({
      namespaceId: namespaceData.namespaceId,
      locales: locales
        .filter(v => Boolean(v.localeKey))
        .map(locale => ({
          localeKey: locale.localeKey,
          defaultText: locale.sourceText,
        })),
    });

    if (res.code === 0) {
      navigate(`/locale/${namespace}/en`);
    } else if (res.code === 5000) {
      message.error("存在重复");
    }
  };

  const onCancel = () => {
    navigate(`/locale/${namespace}/en`);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="h-16 flex-shrink-0 flex items-center p-4 border-b border-solid border-stone-400">
        <span>{namespaceData?.name}</span>
        <span>[{namespaceData?.namespaceId}]</span>
      </div>
      <div className="flex-1">
        <Table
          className="w-full"
          dataSource={locales}
          columns={columns}
          pagination={false}
        />
      </div>
      <div className="border-t border-solid border-gray-300 p-4 flex gap-4 justify-end">
        <Button onClick={onCancel}>取消</Button>
        <Button type="primary" danger onClick={onSubmit}>
          提交
        </Button>
      </div>
    </div>
  );
}
