import axios, { Response } from ".";

export interface LocaleField {
  id: string;
  localeKey: string;
  defaultText: string;
  translateText: string;
}

export const getLocaleList = (params: {
  lang: string;
  namespaceId: string;
}): Promise<Array<LocaleField>> => {
  return axios.get(`/locales/list/${params.lang}/${params.namespaceId}`);
};

export const getLocaleInfo = (params: {
  namespaceId: string;
}): Promise<{
  total: number;
  locales: Array<{ key: string; count: number }>;
}> => {
  return axios.get(`/locales/info/${params.namespaceId}`);
};

export const createLocale = (body: any) => {
  return axios.post("/locales", body);
};

export const deleteLocale = (id: string): Promise<Response<any>> => {
  return axios.delete(`/locales/${id}`);
};
