import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import { Models } from "@rematch/core";
import { project } from "./project";
import { namespace } from "./namespace";

export interface RootModel extends Models<RootModel> {
  namespace: typeof namespace;
  project: typeof project;
}

export const models: RootModel = { project, namespace };

export const store = init({
  models,
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
