import { Breadcrumb } from "antd";
import { RootState } from "@/models";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useMemo } from "react";
import { useQueryParam, StringParam } from "use-query-params";

export default function ProjectBreadcrumb() {
  const currentProject = useSelector(
    (state: RootState) => state.project.currentProject,
  );

  const currentNamespace = useSelector(
    (state: RootState) => state.namespace.currentNamespace,
  );

  const namespaces = useSelector(
    (state: RootState) => state.namespace.namespaces || [],
  );

  const [lang] = useQueryParam("lang", StringParam);
  const { projectId, namespace } = useParams();

  const breadcrumbItems = useMemo(() => {
    const items = [
      {
        title: currentProject.name,
        visible: !!projectId,
      },
      {
        title: currentNamespace.name,
        menu: {
          items: namespaces.map(ns => ({
            key: ns._id,
            label: (
              <Link to={`/locale/${projectId}/${ns.namespaceId}?lang=${lang}`}>
                {ns.name}
              </Link>
            ),
          })),
        },
        visible: !!namespace,
      },
    ];
    return items.filter(v => v.visible);
  }, [
    projectId,
    namespace,
    currentProject,
    currentNamespace,
    namespaces,
    lang,
  ]);

  return (
    <div className="h-16 flex-shrink-0 flex items-center p-4 border-b border-solid border-stone-400">
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
}
