import { useNavigate } from "react-router-dom";
import { getProject } from "@/services/project";
import { useQuery } from "react-query";

export default function Project() {
  const { data: projects } = useQuery(["getProject"], getProject);
  console.log("data:", projects);

  const navigate = useNavigate();

  return (
    <div className="p-4 flex flex-col gap-3">
      {projects?.map(project => {
        return (
          <div
            className="border border-solid border-gray-400 bg-cyan-100 p-4 cursor-pointer"
            onClick={() => {
              navigate(`/namespace/${project._id}`);
            }}>
            <div>{project.name}</div>
            <div>{project.description}</div>
          </div>
        );
      })}
    </div>
  );
}
