import { useParams, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useQuery } from "react-query";
import { Progress } from "antd";
import { useQueryParam, StringParam } from "use-query-params";
import { getLocaleInfo } from "@/services/locale";

export const localeConfigs = [
  {
    locale: "zh",
    label: "中文",
  },
  {
    locale: "en",
    label: "英文",
  },
];

interface LocaleSelectProps {
  namespaceId: string;
}

export default function LocaleSelect(props: LocaleSelectProps) {
  const { namespaceId } = props;

  const [lang, setLang] = useQueryParam("lang", StringParam);

  const { namespace = "" } = useParams();
  const navigate = useNavigate();

  const { data: infoData } = useQuery(
    [`getLocaleInfo-${namespaceId}`],
    () => getLocaleInfo({ namespaceId }),
    {
      enabled: !!namespaceId,
    },
  );

  const total = infoData?.total ?? 0;

  return (
    <div className="w-64 flex-shrink-0 border-r border-solid border-zinc-500">
      {localeConfigs.map(config => {
        const localeCount =
          infoData?.locales.find(v => v.key === config.locale)?.count ?? 0;

        const percent = ((localeCount / total) * 100).toFixed();

        return (
          <div
            onClick={() => setLang(config.locale)}
            className={classnames("p-4 cursor-pointer text-sm", {
              "bg-sky-400": lang === config.locale,
            })}>
            <div>
              <span>{config.label}</span>
              <span>[{config.locale}]</span>
            </div>
            <Progress percent={Number(percent)} showInfo={false} />
            <div>
              <span>翻译</span>
              <span className="ml-2">{percent}%</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
