import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/models";

export default function NameSpace() {
  const { projectId } = useParams();
  const namespaces = useSelector(
    (state: RootState) => state.namespace.namespaces,
  );

  const navigate = useNavigate();

  return (
    <div className="p-4">
      {namespaces?.map(namespace => {
        return (
          <div
            className="border border-solid border-gray-400 bg-cyan-200 p-4 cursor-pointer"
            onClick={() => {
              navigate(`/locale/${projectId}/${namespace.namespaceId}?lang=en`);
            }}>
            <div>{namespace.name}</div>
          </div>
        );
      })}
    </div>
  );
}
