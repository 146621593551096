import { useQuery, useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import {
  getLocaleList,
  LocaleField,
  createLocale,
  deleteLocale,
} from "@/services/locale";
import { Table, Input, Button, Popconfirm } from "antd";
import LocaleSelect from "@/views/locale-select";
import { useMemo, useState } from "react";
import { localeConfigs } from "@/views/locale-select";
import { useQueryParam, StringParam } from "use-query-params";
import { useSelector } from "react-redux";
import { RootState } from "@/models";

const LocalePage = () => {
  const { namespace = "" } = useParams();
  const [lang] = useQueryParam("lang", StringParam);
  const currentNamespace = useSelector(
    (state: RootState) => state.namespace.currentNamespace,
  );

  const navigate = useNavigate();

  const [currentLocale, setCurrentLocale] = useState<LocaleField>();

  const {
    data: locales = [],
    isLoading,
    refetch,
  } = useQuery(
    [`getLocale`, lang, namespace],
    () =>
      getLocaleList({ lang: lang ?? "", namespaceId: currentNamespace._id }),
    {
      enabled: !!namespace && !!lang && !!currentNamespace?._id,
      cacheTime: 0,
    },
  );

  const { mutate } = useMutation(createLocale, {
    onSuccess: () => {
      setCurrentLocale(undefined);
      refetch();
    },
  });

  const label = useMemo(() => {
    return localeConfigs.find(config => config.locale === lang)?.label;
  }, [lang]);

  const columns = [
    {
      title: "Key",
      dataIndex: "localeKey",
      key: "localeKey",
    },
    {
      title: "原文案",
      dataIndex: "defaultText",
      key: "defaultText",
      render: (defaultText: string, item: LocaleField) => {
        return currentLocale?.id === item.id ? (
          <Input.TextArea
            autoSize
            value={currentLocale.defaultText}
            onChange={event => {
              setCurrentLocale({
                ...currentLocale,
                defaultText: event.target.value,
              });
            }}
          />
        ) : (
          <span>{defaultText}</span>
        );
      },
    },
    {
      title: "翻译文案",
      dataIndex: "translateText",
      key: "translateText",
      render: (translateText: string, item: LocaleField) => {
        return currentLocale?.id === item.id ? (
          <Input.TextArea
            autoSize
            value={currentLocale.translateText}
            onChange={event => {
              setCurrentLocale({
                ...currentLocale,
                translateText: event.target.value,
              });
            }}
          />
        ) : (
          <span>{translateText}</span>
        );
      },
    },
    {
      title: "",
      width: 200,
      render: (_, item: LocaleField) => {
        return (
          <div>
            {currentLocale?.id === item.id ? (
              <div className="flex gap-4">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    mutate({
                      namespaceId: currentNamespace._id,
                      localeKey: item.localeKey,
                      defaultText: currentLocale.defaultText,
                      lang,
                      translateText: currentLocale.translateText,
                    })
                  }>
                  保存
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => setCurrentLocale(undefined)}>
                  取消
                </span>
              </div>
            ) : (
              <div>
                <span
                  className="cursor-pointer"
                  onClick={() => setCurrentLocale(item)}>
                  编辑
                </span>
                <Popconfirm
                  title="确认删除这个词条吗？"
                  onConfirm={async () => {
                    const res = await deleteLocale(item.id);
                    if (res.code === 0) {
                      refetch();
                    }
                  }}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <LocaleSelect namespaceId={currentNamespace?._id} />
        <div className="flex-1 h-full flex flex-col">
          <div className="flex justify-between items-center p-4">
            <div className="font-bold text-2xl">{label}</div>
            <div>
              <Button
                danger={true}
                type="primary"
                onClick={() => navigate(`/locale/create_source/${namespace}`)}>
                新增文案
              </Button>
            </div>
          </div>
          <div className="p-4 overflow-y-auto">
            <Table
              className="w-full"
              dataSource={locales}
              columns={columns}
              loading={isLoading}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 20,
                showTotal: (total: number) => <div>{total}</div>,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalePage;
